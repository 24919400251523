import React from "react";
import { Link } from "react-router-dom";
import { FaUserPlus, FaSignInAlt } from "react-icons/fa"; // Import icons
import "./layout.css";

const Layout = ({ children }) => {
  return (
    <div className="layout">
      <header className="header">
        <div className="nav-links">
          <Link to="/" className="nav-link">
            <FaUserPlus className="icon" /> Register
          </Link>
          <Link to="/login" className="nav-link">
            <FaSignInAlt className="icon" /> Login
          </Link>
        </div>
      </header>

      <div className="logo">
      <img src="/delusion_logo.png" alt="Delusion Logo" />
    </div>
      <main className="content">{children}</main>
    </div>
  );
};

export default Layout;
