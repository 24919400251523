import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const [isAuthorized, setIsAuthorized] = useState(null); // `null` means checking status
  const [authChecked, setAuthChecked] = useState(false); // Flag to prevent multiple checks

  const checkAuthorization = async () => {
    // If already checked, return to avoid multiple checks
    if (authChecked) return;

    try {
      const response = await fetch("https://delusion-backend-38a1c2e67e35.herokuapp.com/is_authorized/", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        credentials: "include", // Include cookies
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const jsonResponse = await response.json();
      console.log(jsonResponse); // Log the response for debugging

      // Check if the response indicates authorization
      if (jsonResponse === "Authorized") {
        setIsAuthorized(true); // User is authorized
      } else {
        setIsAuthorized(false); // User is not authorized
        navigate("/login"); // Redirect to login
      }
    } catch (error) {
      console.error("Error:", error); // Log any errors during the fetch
      setIsAuthorized(false); // Set as not authorized on error
      navigate("/login"); // Redirect to login if there's an error
    } finally {
      setAuthChecked(true); // Mark the check as completed
    }
  };

  useEffect(() => {
    checkAuthorization(); // Perform authorization check on mount
  }, []); // Empty dependency array to run this effect only once

  return (
    <AuthContext.Provider value={{ isAuthorized }}>
      {isAuthorized === null ? <div>Loading...</div> : children} {/* Show loading while checking */}
    </AuthContext.Provider>
  );
};
