import React, { useState } from "react";
import "./signup.css";
import { Link } from "react-router-dom";
import sha256 from 'js-sha256';

const Signup = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    reffered_by_code: ""
  });
  const [errors, setErrors] = useState({
    name: "",
    email: "",
    password: "",
    reffered_by_code: ""
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  
  const validateForm = () => {
    let newErrors = { 
      name: "", 
      email: "", 
      password: "", 
      reffered_by_code: "" 
    };
    let isValid = true;

    // Validate Name
    if (!formData.name) {
      newErrors.name = "Name is required.";
      isValid = false;
    }

    // Validate Email
    if (!formData.email) {
      newErrors.email = "Email is required.";
      isValid = false;
    }

    // Validate Password
    if (!formData.password) {
      newErrors.password = "Password is required.";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const hash_var = (input) => {
    return sha256(input);
  };

  const validate_username = async () =>
  {
    var is_ok = false;
    var response;
    let newErrors ={ ...errors };
    try {  

      response = await
      fetch('https://delusion-backend-38a1c2e67e35.herokuapp.com/user/validate_id/', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
        
          "username" : formData.name,
          "email" : formData.email,
          "reffered_by_code" : formData.reffered_by_code
        })
       
      })

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      var json_response = await response.json()
      console.log(json_response)
      newErrors.email = "";
      if(json_response == "ok")
      {
        setResponseMessage("User registered successfully");
        is_ok = true;
      }
      else if(json_response == "duplicate_username")
      {  
        newErrors.name = "username is taken";
        newErrors.email = "";
        setErrors(newErrors);
      }
      else if(json_response == "duplicate_email")
      {
        newErrors.email = "email is already in use";
        newErrors.name = "";
        setErrors(newErrors);
      }
      else if(json_response == "duplicate_both")
      {
        newErrors.email = "email is already in use";
        newErrors.name = "username is taken";
        setErrors(newErrors);
      }
      else if(json_response == "invalid_refferal")
      {
        newErrors.email = "";
        newErrors.name = "";
        newErrors.reffered_by_code = "invalid refferal code";
        setErrors(newErrors);
      }
      
    } catch (error) {
      setResponseMessage("Error: " + error.message);
      console.error("Error:", error);
      is_ok = false 
    } finally {
      setIsSubmitting(false);
      return is_ok;
    }

  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      setIsSubmitting(true);
      setResponseMessage("");
      
      var validated = await validate_username()
      console.log(validated)
      if(validated)
      {
        try {  
          let passHash = await hash_var(formData.password);
          let response = await
          fetch('https://delusion-backend-38a1c2e67e35.herokuapp.com/user/add_id/', {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
            
              "username" : formData.name,
              "password" : passHash,
              "email" : formData.email,
              "reffered_by_code" : formData.reffered_by_code
            }),
            
          })
    
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
    
          var json_response = await response.json();
          console.log(json_response);

        } catch (error) {
          setResponseMessage("Error: " + error.message);
          console.error("Error:", error);
        } finally {
          setIsSubmitting(false);
      
        }
      }
    }
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
 
  return (
    <div className="addUser">
      <h3 className="signup-heading"></h3>
      <form className="addUserForm" onSubmit={handleSubmit}>
        <div className="inputGroup">
          <label htmlFor="name">Username:</label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            placeholder="username"
          />
          {errors.name && <p className="error-message">{errors.name}</p>}
        </div>

        <div className="inputGroup">
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="email"
          />
          {errors.email && <p className="error-message">{errors.email}</p>}
        </div>

        <div className="inputGroup">
          <label htmlFor="password">Password:</label>
          <input
            type="password"
            id="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            placeholder="password"
          />
          {errors.password && <p className="error-message">{errors.password}</p>}
        </div>

        <div className="inputGroup">
          <label htmlFor="refferal-code">Refferal code:</label>
          <input
            type="text"
            id="refferal"
            name="reffered_by_code"
            value={formData.reffered_by_code}
            onChange={handleChange}
            placeholder="code"
          />
          {errors.reffered_by_code && <p className="error-message">{errors.reffered_by_code}</p>}
        </div>

        <button type="submit" className="btn btn-success" disabled={isSubmitting}>
          {isSubmitting ? "Submitting..." : "Register"}
        </button>
      </form>

      {responseMessage && <p className="response-message">{responseMessage}</p>}


    </div>
  );
};

export default Signup;
