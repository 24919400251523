import React, { useState, useEffect, useRef, useCallback } from "react";
import { FaCrosshairs, FaEye, FaRunning, FaShieldAlt, FaHeadset } from "react-icons/fa";
import "./billing.css";

const Billing = () => {
  const [isBuying, setIsBuying] = useState(false);
  const [amount, setAmount] = useState(1500); // Default amount for 1 month subscription
  const [subscriptionStatus, setSubscriptionStatus] = useState("Loading...");
  const [visibleFeatures, setVisibleFeatures] = useState({});
  const featuresRef = useRef(null);

  const features = [
    { 
      title: "Aimbot",
      icon: <FaCrosshairs size={40} />,
      description: "Customizable aimbot with bone selection, FOV control, smooth aim, and recoil control system. Includes target prioritization and visibility checks." 
    },
    { 
      title: "ESP", 
      icon: <FaEye size={40} />,
      description: "Full ESP suite with player boxes, health bars, equipment info, and glow. Customizable colors and display options for enemies and teammates." 
    },
    { 
      title: "Key Features", 
      icon: <FaRunning size={40} />,
      description: "Humanized Aimbot and other features designed to mimic human behavior and avoid detection." 
    },
    { 
      title: "Kernel Driver", 
      icon: <FaShieldAlt size={40} />,
      description: "Read-only kernel cheat assuring 100% safety." 
    },
    { 
      title: "Misc", 
      icon: <FaShieldAlt size={40} />,
      description: "Soon: radar, and other miscellaneous features to enhance your gameplay." 
    },
    { 
      title: "Premium Support", 
      icon: <FaHeadset size={40} />,
      description: "24/7 Discord support, regular updates, and assistance for any technical issues." 
    },
  ];

  const handleBuyNow = async (selectedAmount, months) => {
    setIsBuying(true);
    setAmount(selectedAmount);

    try {
      const response = await fetch(
        "https://delusion-backend-38a1c2e67e35.herokuapp.com/create-checkout-session",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify({
            amount: selectedAmount,
            months: months,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to create checkout session");
      }

      const { url } = await response.json();
      window.location.href = url;
    } catch (error) {
      console.error("Error creating checkout session:", error);
      setIsBuying(false);
    }
  };

  useEffect(() => {
    const fetchSubscriptionStatus = async () => {
      try {
        const response = await fetch(
          "https://delusion-backend-38a1c2e67e35.herokuapp.com/sub_expire_date",
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            credentials: "include",
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch subscription status");
        }

        const data = await response.json();
        setSubscriptionStatus(data.status);
      } catch (error) {
        console.error("Error fetching subscription status:", error);
        setSubscriptionStatus("Error fetching status");
      }
    };

    fetchSubscriptionStatus();
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setVisibleFeatures(prev => ({
              ...prev,
              [entry.target.dataset.index]: true
            }));
          }
        });
      },
      { threshold: 0.1 }
    );

    // Wait for next frame to ensure DOM elements exist
    requestAnimationFrame(() => {
      if (featuresRef.current) {
        const cards = featuresRef.current.querySelectorAll('.feature-card');
        cards.forEach(card => observer.observe(card));
      }
    });

    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    // Set all features visible immediately for testing
    const allVisible = {};
    features.forEach((_, index) => {
      allVisible[index] = true;
    });
    setVisibleFeatures(allVisible);
    
    // Log to verify state update
    console.log('Setting all features visible:', allVisible);
  }, []);

  return (
    <div className="dashboard-account">
      {/* <div className="image-container">
        <img
          src="/cs2.png"
          alt="Product Image"
          className="game-image"
        />
      </div> */}



      <div className="video-section">
        <iframe
          width="100%"
          height="500px"
          src="https://www.youtube.com/embed/RA6M1UqNkqE"
          title="YouTube video"
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>

      <div className="subscription-content">
        <div className="subscription-options">
          <div className="subscription-option" onClick={() => handleBuyNow(1500, 1)}>
            <p className="subscription-title">CS2 30 Days</p>
            <p className="subscription-price">$15.00</p>
          </div>
          <div className="subscription-option" onClick={() => handleBuyNow(3500, 3)}>
            <p className="subscription-title">CS2 90 Days</p>
            <p className="subscription-price">$30.00</p>
          </div>
          <div className="subscription-option" onClick={() => handleBuyNow(12000, 1200)}>
            <p className="subscription-title">CS2 Lifetime</p>
            <p className="subscription-price">$120.00</p>
          </div>
        </div>
        <div className="subscription-status-container">
          <p className="subscription-status">
            <strong>
              {subscriptionStatus === "Inactive"
                ? "Subscription Status:"
                : "Active until:"}
            </strong>
          </p>
          <p className="subscription-status">
            <strong>
              {subscriptionStatus === "Inactive"
                ? "Inactive"
                : subscriptionStatus}
            </strong>
          </p>
        </div>
      </div>

      <div className="features-section">
        <h2>Features</h2>
        <div className="features-grid" ref={featuresRef}>
          {features.map((feature, index) => (
            <div
              key={index}
              data-index={index}
              className={`feature-card ${visibleFeatures[index] ? 'visible' : ''}`}
            >
              <div className="feature-icon">{feature.icon}</div>
              <h3>{feature.title}</h3>
              <p>{feature.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Billing;

