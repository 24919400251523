import React, { useState, useEffect } from "react";
import "./login.css";
import { useNavigate } from "react-router-dom";
import sha256 from 'js-sha256';



const Login = () => {
  const [formData, setFormData] = useState({
    name: "",
    password: "",
  });
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");

  const navigate = useNavigate(); // For redirection after login

  const checkAuthorization = async () => {
    try {
      const response = await fetch("https://delusion-backend-38a1c2e67e35.herokuapp.com/is_authorized/", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        credentials: "include", // Include cookies
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const jsonResponse = await response.json();
      console.log(jsonResponse); // Log the response for debugging

      // Check if the response indicates authorization
      if (jsonResponse === "Authorized") {
        navigate("/dashboard/account");
      }
    } 
    catch (error) {
      console.log(error)
    } 
    
  };

  useEffect(() => {
    checkAuthorization(); // Perform authorization check on mount
  }, []); // Empty dependency array to run this effect only once

  // Helper function to hash input
  const hash_var = (input) => {
    return sha256(input);
  };

  // Validate input fields
  const validateForm = () => {
    const newErrors = {};
    let isValid = true;

    if (!formData.name) {
      newErrors.name = "Username is required.";
      isValid = false;
    }

    if (!formData.password) {
      newErrors.password = "Password is required.";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      setIsSubmitting(true);
      setResponseMessage("");

      try {
        const userHash = await hash_var(formData.name);
        const passHash = await hash_var(formData.password);

        const response = await fetch("https://delusion-backend-38a1c2e67e35.herokuapp.com/user/login/", {
          method: "POST",
          headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
      
          },
          credentials: "include",  // Include cookies with this request
          body: JSON.stringify({
            "username": formData.name,
            
            "password": passHash,
          }),
        });
        
        const jsonResponse = await response.json();
        console.log(jsonResponse)
        
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        if (jsonResponse.valid) {
          // Store session token securely
       
          setResponseMessage("Login successful!");
          navigate("/dashboard/account");
        } else {
          setResponseMessage("Invalid login credentials.");
        }
      } catch (error) {
        setResponseMessage("Error: " + error.message);
        console.error("Error:", error);
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  // Handle input change
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div className="login-container">
   
      <form className="login-form" onSubmit={handleSubmit}>
        <div className="inputGroup">
          <label htmlFor="name">Username:</label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            placeholder="Enter your username"
          />
          {errors.name && <p className="error-message">{errors.name}</p>}
        </div>

        <div className="inputGroup">
          <label htmlFor="password">Password:</label>
          <input
            type="password"
            id="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            placeholder="Enter your password"
          />
          {errors.password && <p className="error-message">{errors.password}</p>}
        </div>

        <button
          type="submit"
          className="login-button"
          disabled={isSubmitting}
        >
          {isSubmitting ? "Logging in..." : "Login"}
        </button>
      </form>

      {responseMessage && (
        <p className={`login-response-message ${responseMessage.includes("success") ? "login-success-message" : "login-error-message"}`}>
          {responseMessage}
        </p>
      )}
    </div>
  );
};

export default Login;
